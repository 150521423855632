@import 'utils';

.moduleCardWrapper {
  @extend %content-block;
  padding: rem-calc(24);
  border-radius: rem-calc(16);

  @include breakpoint(medium down) {
    padding: rem-calc(16);
  }
}

.cardHeader {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-auto-rows: auto;
  place-items: center;
  column-gap: rem-calc(12);
  row-gap: rem-calc(8);

  justify-items: end;
}

.courseDropdown {
  grid-column: 4;
  grid-row: 1;
}

.title {
  @include text-platform-h2;
  color: getColor($c-text-default);
  word-break: break-word;
}

.moduleCardIcon {
  grid-row: 1;
  align-self: flex-start;

  @include breakpoint(medium up) {
    grid-row: 1;
  }

  @include breakpoint(small down) {
    grid-row: 1;
  }
}

.image {
  @include dark-mode-styles {
    filter: invert(100%) grayscale(100%) brightness(10);
  }
}

.moduleCardIconStretch {
  @include breakpoint(xlarge up) {
    grid-row: 1 / 3;
  }

  @include breakpoint(medium down) {
    grid-row: 1 / 3;
  }
}

.description {
  @include text-platform-secondary;
  color: getColor($c-text-secondary);

  grid-column: 2 / 5;
  justify-self: start;

  @include breakpoint(medium up) {
    grid-column: 1 / -1;
    .hasDescription {
      grid-row: 3;
    }
  }

  @include breakpoint(xlarge up) {
    grid-column: 2 / 5;
    grid-row: unset;
  }

  @include breakpoint(medium down) {
    grid-column: 2 / 5;
    grid-row: unset;
  }

  @include breakpoint(small down) {
    grid-column: 1 / -1;
    .hasDescription {
      grid-row: 3;
    }
  }
}

.cardTitleInfo:not(:empty) {
  justify-self: start;

  display: flex;
  gap: rem-calc(12);

  @include breakpoint(medium up) {
    grid-row: 2;
    grid-column: 1 / -1;
  }

  @include breakpoint(xlarge up) {
    grid-row: unset;
    grid-column: unset;
  }

  @include breakpoint(medium down) {
    grid-row: unset;
    grid-column: unset;

    flex-wrap: wrap;
    gap: rem-calc(6);
  }

  @include breakpoint(small down) {
    grid-row: 2;
    grid-column: 1 / -1;
  }
}

.modulesContent {
  padding: rem-calc(12 0);
}

.description:empty,
.bottomContent:empty {
  display: none;
}
